// import "./App.css";
import Home from "./components/Home";
import NavigationBar from "./components/Navvbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Footer from "./components/Footer";
import Egiftcoupons from "./pages/Egiftcoupons";
import Smartmealcard1 from "./pages/Smartmealcard1";
import Timeassistancesystem from "./pages/Timeassistancesystem";
import Visitormanagement from "./pages/Visitormanagement";
import Idcardprinting from "./pages/Idcardprinting";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Allproducts from "./pages/Allproducts";
import Privacypolicy from "./pages/Privacypolicy";
import Termcondition from "./pages/Termcondition";
import Incidentresponse from "./pages/Incidentresponse";
import Blog from "./pages/Blog";


function App() {
  return (
    <>
      <Router>
        
   
        <NavigationBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Allproducts />} />
          <Route path="/smartmealcard" element={<Smartmealcard1 />} />
          <Route path="/egiftcoupons" element={<Egiftcoupons />} />
          <Route
            path="/timeattendancesystem"
            element={<Timeassistancesystem />}
          />
          <Route path="/visitormanagement" element={<Visitormanagement />} />
          <Route path="/idcardprinting" element={<Idcardprinting />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<Blog/>}/>

          <Route path="/contact" element={<Contact />} />
          <Route path="/privacypolicy" element={<Privacypolicy/>}/>
          <Route path="/termcondition" element={<Termcondition/>}/>
          <Route path="/incidentresponsepolicy" element={<Incidentresponse/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
