import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const Smartmealcard1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [comapny, setCompany] = useState("");
  const [selectedproduct, setSelectedProduct] = useState("");

  const [error , setError] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) { // Checks if input consists of 10 digits or less
      setNumber(inputValue);
      if (inputValue.length !== 10) {
        setError('Number must be exactly 10 digits long');
      } else {
        setError('');
      }
    }
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "service_k6lmbm8";
    const templateId = "template_tq55tcc";
    const publicKey = "kHo1cvgHPOMZrnrRB";

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_number: number,
      from_email: email,
      from_company: comapny,
      from_product: selectedproduct,
   
    
      to_name: "",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setName("");
        setNumber("");
        setEmail("");
        setCompany("");
        setMessage("");
        setSelectedProduct("");
      })

      

      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <>
      <section id="about-5">
        <header className="header-container">
          <img
            src="./img/card-3.jpg"
            className="header-image"
            alt="HeaderImage"
          />
          <div className="header-text">
            <p className="para-50">Smart Meal Card</p>
          </div>
        </header>
      </section>

      <section id="smart-3">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              {/* <h2 className="smart-heading">Smart Meal Card</h2> */}
              <p className="smart-para">
                {" "}
                Our Smart Meal Card is akin to a bank’s credit / debit card
                system. It helps companies automate canteen coupons. Our end to
                end solution involves card issuance, deployment of transaction
                terminals in canteens and web based reporting. Web access is
                provided to corporate, employees and merchants to access data
                online. Our mobile terminals can be carried by merchant for
                serving at employees’ work stations. The system has a slew of
                features to customize instantly to varying requirements of
                clients.
              </p>

              <p className="smart-para-1">
                With our system, you can do away with the hassles of monthly
                procurement, distribution and reconciliation of paper coupons.
                Canteen audit and monthly canteen usage data can be accessed
                easily. The system is quick and easy to deploy and does not
                require any system integration.
              </p>
            </div>
            <div class="col-md-6">
              <img
                className="resize-smart-1"
                src="./img/card-1.avif"
                alt="card-1"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="features">
        <h2 className="features-heading">Smart Meal Card Features</h2>

        <div class="container text-center">
          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                One second to avoid queuing at counters
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                Contact less smart card or your compatible ID card or mobile
                phone can also be used instead of the card
              </p>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                {" "}
                Mobile terminals to allow serving at employees’ work station /
                conference rooms
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                Can work in both prepaid / Post paid mode
              </p>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                {" "}
                Daily or monthly usage limits can be set
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">Multiple menus for easy transaction</p>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                {" "}
                Paper less transaction though system has provision for printing
                receipts
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                Customised setup for permanent, contract or guest users
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="application">
        <h2 className="appli-heading">Smart Meal Card Benefits</h2>

        <div class="container ">
          <div class="row">
            <div class="col-md-3 smart-flip">
              {" "}
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    {/* <i class="fas fa-user user-icon"></i> */}
                    <i class="fas fa-building building-icon"></i>
                    <h2 className="flip-head">Corporate</h2>
                  </div>
                  <div className="flip-card-back">
                    <ul className="ul-2">
                      <li className="li-2">
                        Prevents misuse of meal allowance thereby optimizing
                        canteen expenditure.
                      </li>

                      <li className="li-2">
                        Easy employee subsidy calculation and audit basis online
                        reports.
                      </li>

                      <li className="li-2">
                        Fully managed service thereby complete peace of mind.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 smart-flip">
              {" "}
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <i class="fas fa-store store-icon"></i>
                    <h2 className="flip-head">Merchant</h2>
                  </div>
                  <div className="flip-card-back">
                    <ul className="ul-2">
                      <li className="li-2">
                        Online remote monitoring to prevent cash pilferage by
                        staff.
                      </li>

                      <li className="li-2">
                        Quick and easy bill preparation at the end of the month.
                      </li>

                      <li className="li-2">
                        Fully managed service thereby complete peace of mind.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 smart-flip">
              {" "}
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    {/* <i class="fas fa-user-tie user-icon"></i> */}
                    <i class="fa fa-users user-icon"></i>
                    <h2 className="flip-head">Employee</h2>
                  </div>
                  <div className="flip-card-back">
                    <ul className="ul-2">
                      <li className="li-2">Fast and easy payments</li>

                      <li className="li-2">
                        Unlike paper instruments, can block card and avoid loss
                        when card is misplaced.
                      </li>

                      <li className="li-2">
                        Can access usage data online and claim tax benefit.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </section>

      <section id="enquiry">
        <header className="header-container-1">
          <img
            src="./img/enquiry-banner.jpg"
            className="header-image-1"
            alt="enquiryImage"
          />
          <div className="header-text-1">
            <h2 className="en-head">
              We are here to answer your questions 24/7
            </h2>
            {/* <p className="en-para">Need A Enquiry</p> */}
            <button className="en-btn" onClick={handleButtonClick}>
              Enquiry Now
            </button>
          </div>
        </header>

        {isModalOpen && (
          <div className="conatiner modal">

            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <h2 className="en-head-2">Enquiry Form</h2>
              <form onSubmit={handleSubmit} className="emailForm">
                <input
                  className="input-3"
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />{" "}
                <br />
                <input
                  className="input-3"
                  type="number"
                  placeholder="Your Number"
                  value={number}
                  required
                  onChange={handleChange}
                />{" "}
                 {error && <p style={{ color: 'red' }}>{error}</p>}
                <br />
                <input
                  className="input-3"
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />{" "}
                <br />
                <input
                  className="input-3"
                  type="text"
                  placeholder="Your Company name"
                  value={comapny}
                  required
                  onChange={(e) => setCompany(e.target.value)}
                />{" "}
                <br />

                <select
                  className="input-3"
                  value={selectedproduct}
                  required
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="">Select a Product List</option>
                  <option value="Smart Meal Card">Smart Meal Card</option>
                  <option value="Egift Coupons">EGift Coupons</option>
                  <option value="Time Attendance System">Time Attendance System</option>
                  <option value="Visitor Management">Visitor Management</option>

                  <option value="Id Card Printing">Id Card Printing</option>

                </select>



                <br />
                <textarea
                  className="textarea-3"
                  placeholder="Message"
                  cols="30"
                  rows="10"
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>{" "}
                <br />
                <button type="submit" className="btn-email-pop">
                  Send Email
                </button>
              </form>
            </div>
       
          </div>
        )}
      </section>
    </>
  );
};

export default Smartmealcard1;
