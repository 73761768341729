import React, { useEffect } from "react";

const Privacypolicy = () => {


    document.title = "Privacy And Policy";

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    
  return (
    <>
      <section id="about-5">
      <header className="header-container-10">
        <img
          src="./img/header-img-section.jpg"
          className="header-image-10"
          alt="HeaderImage"
        />
        <div className="header-text-10">
          {/* <p className="para-header-6"> About Us</p> */}
          <h2 className="header-head-10">Privacy & Policy</h2>
        </div>
      </header>

      </section>

      <section id="policy-21">
        <div className="container">
          <ol className="ol-1">
            <li className="li-10">
              Camp Card Solutions Private Limited, herein referred to as
              "Campcard," has established this policy, herein referred to as the
              "Privacy Policy," to articulate its procedures concerning the
              collection of personal information from customers through various
              channels including:
            </li>
          </ol>
          <ol className="ol-2" type="a">
            <li className="li-11">Our website, www.campcardsolutions.com</li>
            <li className="li-11">
              Our mobile applications designed for merchants and users
            </li>
            <li className="li-11">
              Information provided to us via email or any other means for user
              registration
            </li>
            <li className="li-11">
              Cookies and other tracking technologies, which gather specific
              information when customers interact with our website and
              applications.
            </li>
          </ol>
          <ol className="ol-1" start="2">
            <li className="li-10">
              This Privacy Policy is intended to elucidate Campcard's practices
              and approach towards handling and safeguarding customer personal
              information.
            </li>
          </ol>

          <ol className="ol-1" start="3">
            <li className="li-10">
              Campcard is dedicated to preserving the privacy of the customer
              and ensuring the continuous trust placed in us with regard to
              their Personal Information. For the purposes of this Privacy
              Policy, "Personal Information" refers to information shared by the
              customer through the website, mobile application, and/or
              telephonic conversation for specified purposes, and shall
              encompass:
            </li>
          </ol>
          <ol className="ol-2" type="a">
            <li className="li-11">
               Personal contact information, which includes any details
              enabling Campcard to contact customers personally or any
              information that may identify such customers (e.g., name, email
              address, and phone number).
            </li>

            <li className="li-11">
               Account login information, incorporating any data required by
              the customer to establish a user account with Campcard (e.g.,
              login ID/email, username, password, and security question/answer).
            </li>
            <li className="li-11">
               Consumer feedback, encompassing information shared by
              customers with Campcard regarding their experiences with Campcard
              products and services (e.g., comments, suggestions, testimonials,
              and feedback related to Campcard and its products and services).
            </li>
          </ol>

          <ol className="ol-1" start="4">
            <li className="li-10">
              Collection and use of personal information: Campcard collects and
              uses personal information only as necessary for the purposes for
              which it was obtained. Campcard does not use any customer data for
              3rd party marketing purposes. Campcard may use the personal
              information for some or all of the following purposes:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11">
              Orders - to process and dispatch customer's orders and to inform
              customers about the status of their orders. Please note that there
              are no third party platforms that sell Campcard’s products and
              every transaction made on Campcard’s platform shall be governed by
              this privacy policy.
            </li>

            <li className="li-11">
              Account maintenance - to create and maintain customer's accounts
              with Campcard, including administering any consumer loyalty or
              rewards programs (if any) that are associated with their account.
            </li>

            <li className="li-11">
              Consumer service - to provide customers with consumer service,
              including responses to their inquiries, complaints and general
              feedback about our products. Consumer service may be provided
              through various forms of communication, including via email,
              letter, telephone and online chat features.
            </li>

            <li className="li-11">
              Other communications - to provide customer with intimations where
              customer have opted-in to receiving such communications (including
              information about Campcard, its products and services,
              competitions and promotions). These can be shared via electronic
              means (e.g. SMS, emails and app notifications)
            </li>

            <li className="li-11">
              Other specific purposes - Campcard may use customer personal
              information for other specific business purposes, including to
              maintain the day-to-day operation and security of Campcard’s
              platform, to conduct internal demographic studies or audits, to
              enhance customer experience.
            </li>
          </ol>

          <ol className="ol-1" start="5">
            <li className="li-10">
              Scope and Acceptance of this Privacy Policy: This Privacy Policy
              pertains to the personal information gathered by Campcard for the
              provision of its products and services to customers. Customers
              have the right to periodically review, correct, or amend the
              information provided by them. In the event a customer chooses not
              to provide information to Campcard, Campcard shall not be held
              liable to provide any services to such customer.
            </li>
          </ol>

          <ol className="ol-1" start="6">
            <li className="li-10">
              Customers retain the option to opt out of the Campcard platform at
              any time by unsubscribing from the Campcard platform. By utilizing
              the Campcard platform or providing personal information to
              Campcard, the customer consents to and accepts the practices
              delineated in this Privacy Policy. Campcard disclaims
              responsibility for the authenticity of personal information
              provided by the customer. Campcard reserves the right to modify
              this Privacy Policy on a quarterly basis or as necessitated by
              changes in law, applicable standards, or technological
              advancements. It is encouraged that customers regularly review
              this Privacy Policy to stay informed about any changes made by
              Campcard and to understand how their personal information may be
              utilized.
            </li>
          </ol>

          <ol className="ol-1" start="7">
            <li className="li-10">
              Sharing of Personal Information by Campcard:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11">
              Campcard and/or Campcard’s platform refrain from sharing the
              customer's personal information with any individual, unless
              specific consent has been provided by the customer for such
              sharing.
            </li>

            <li className="li-11">
              Campcard may disclose the customer's personal information if
              compelled to do so by law and/or regulatory authority, or if, in
              Campcard's good faith judgment, such legal disclosure is
              reasonably necessary to comply with legal processes or respond to
              any claims.
            </li>

            <li className="li-11">
              In the event of a full or partial merger with, or acquisition of
              all or part of Campcard or Campcard’s platform by another company,
              the acquiring entity would gain access to the information
              maintained by Campcard, which may encompass personal information.
            </li>
          </ol>

          <ol className="ol-1" start="8">
            <li className="li-10">
              Affiliates: Campcard reserves the right to furnish Personal
              Information of the Customer to its affiliates or related companies
              (if any) solely for legitimate business purposes. The Client
              acknowledges and agrees not to be a party to any disputes arising
              from commercial activities between Campcard and its customers. It
              is emphasized that there exists a direct contractual agreement
              between Campcard and the Vendors, and the Client is not
              commercially involved in the same.
            </li>
          </ol>

          <ol className="ol-1" start="9">
            <li className="li-10">
              Data security: Campcard undertakes to comply with reasonable
              security practices and procedures as mandated under applicable
              laws including but not limited to Digital Personal Data Protection
              Act, 2023 and rules made there under. In order to keep personal
              information of customer secure, Campcard has implemented a number
              of security measures, including:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11">
              Secure operating environments - Campcard stores personal
              information of customers in secure operating environments and only
              accessible to Campcard's employees, agents and contractors on a
              need-to-know basis. Campcard also follows generally accepted
              industry standards in this respect. The data shared by the
              customers at the time of registration will be used for operational
              and transactional purposes only. The data will not be used for any
              commercial and promotional purposes.
            </li>
            <li className="li-11">
              Encryption for payment information - Campcard uses
              industry-standard encryption to provide protection for sensitive
              financial information, such as credit card/debit card/UPI
              address/Sodexo meal pass/online banking information sent over the
              Internet, through third party payment gateway providers. Campcard
              shall also ensure that all such third party payment gateway
              providers comply with PCI DSS (Payment Card Industry Data Security
              Standard)
            </li>

            <li className="li-11">
              Prior authentication for account access - Campcard requires its
              registered consumers to verify their identity (e.g. login ID and
              password) before they can access or make changes to their account.
              This is aimed to prevent unauthorized accesses. Please note that
              these protections do not apply to personal data you choose to
              share in public areas such as on community Platforms.
            </li>
          </ol>

          <ol className="ol-1" start="10">
            <li className="li-10">
              Retention: Campcard commits to retaining Personal Information of
              the Customer only for the duration necessary for the stated
              purpose, considering Campcard's obligation to address queries,
              resolve issues, offer improved and new services, and adhere to
              legal requirements under Applicable Laws. This implies that
              Campcard may retain the personal information of the customer for a
              reasonable period following their last interaction. Once the
              personal information collected by Campcard is no longer deemed
              necessary, it shall be securely destroyed or deleted in compliance
              with established security protocols.
            </li>
          </ol>

          <ol className="ol-1" start="11">
            <li className="li-10">
              Data Subjects Rights: Campcard ensures that the rights of data
              subjects are respected and upheld when Campcard processes personal
              data. Data subject rights include:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11">
              Data Subject Access Request: Data subjects can request information
              about the personal data Campcard processes about them.
            </li>

            <li className="li-11">
              Data Subject Delete Request: Data subjects can request the
              deletion of personal data processed by Campcard.
            </li>

            <li className="li-11">
              Data Subject Edit Request: Data subjects can request corrections
              to personal data that Campcard processes.
            </li>

            <li className="li-11">
              Data Subject Export Request: Data subjects can request the
              extraction of personal data processed by Campcard.
            </li>

            <li className="li-11">
              Data Subject Restrict Request: Data subjects can request
              restrictions on the processing of their personal data.
            </li>

            <li className="li-11">
              Objection Request: Data subjects can request the cessation of
              processing their personal data.
            </li>
          </ol>

          <ol className="ol-1" start="12">
            <li className="li-10">
              Exception - Some exceptions to Data Subject Rights requirements
              allow Campcard to refuse or charge fees for Data Subject Access
              Requests if they are manifestly unfounded, excessive, or
              repetitive. Campcard must provide valid reasons for such refusals
              or fees and notify the relevant authority if a disagreement
              arises.
            </li>
          </ol>

          <ol className="ol-1" start="13">
            <li className="li-10">
              Data Transfer: Campcard shall not transfer any personal
              information of the customer, to any person in India or abroad,
              unless:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11">
              Such transfer is necessary for the performance of the lawful
              contract between the Campcard or the customer or customer has
              consented to such transfer.
            </li>

            <li className="li-11">
              The person to whom personal information is transferred has
              undertaken to provide data protection as required under the
              applicable laws.
            </li>
          </ol>

          <ol className="ol-1" start="14">
            <li className="li-10">
              Contact us: Customer may contact Mr. Ganesh Giram ("Grievance
              Officer") in the event of any discrepancies or grievances with
              respect to processing of information and the Grievance Officer
              shall address all such discrepancies and grievances within one
              month of receipt of such grievances. Campcard acts as a "Data
              Controller" for the personal information it processes in the
              framework of this Privacy Policy. In case of any questions or
              comments regarding this Privacy Policy or Campcard's personal
              information collection practices, customers may contact Campcard
              by:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11"> Phone: +91 22 66 7373 00 </li>
            <li className="li-11"> E-mail: hr@Campcardsolutions.com</li>
            <li className="li-11">
              {" "}
              Address: Camp card Solutions Pvt. Ltd, 1215 B, Lodha Supremus,
              Kolshet Road, Thane (W) Maharashtra 400 607
            </li>
          </ol>

          <ol className="ol-1" start="15">
            <li className="li-10">
               Customers may contact Mr. Nilesh Sonawane (Data protection
              officer) who is responsible for overseeing data protection matters
              within our organization. The DPO ensures that we comply with data
              protection laws and regulations and acts as a point of contact for
              data subjects and regulatory authorities. If there are any
              questions, concerns, or requests related to the processing of the
              personal data, including inquiries related to data subject rights,
              customers may contact our Data Protection Officer at
              director@campcardsolutions.com
            </li>
          </ol>

          <ol className="ol-1" start="16">
            <li className="li-10">
            	Policy Change Log Table Sn. Reviewed and Approved by: Effective Date of Modified Policy:
            </li>
          </ol>

          <ol className="ol-2" type="a">
            <li className="li-11">
            	Pradip D. - Head of Product February 3rd, 2016
            </li>

            <li className="li-11">
            	Rekha Jha – Director Sales Jan 3rd, 2024
            </li>
          </ol>

          <ol className="ol-1" start="17">
            <li className="li-10">
            	Laws applicable in the territory of India.
            </li>
          </ol>
        </div>
      </section>
    </>
  );
};

export default Privacypolicy;
