import React, { useEffect } from "react";

const Incidentresponse = () => {

    document.title = "Incident Response Policy"

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
         <section id="about-5">
      <header className="header-container-10">
        <img
          src="./img/header-img-section.jpg"
          className="header-image-10"
          alt="HeaderImage"
        />
        <div className="header-text-10">
          {/* <p className="para-header-6"> About Us</p> */}
          <h2 className="header-head-10">Incidence Response Policy</h2>
        </div>
      </header>

      </section>

      <section id="policy-21">
        <div className="container">
          <div>
            <h2 className="incident-head">Incidence Response Policy</h2>
            <p className="incident-para">
              Campcard employs a robust information security incident response
              process for prompt detection, response, and reporting of
              incidents. The goal is to minimize loss, mitigate exploited
              weaknesses, and swiftly restore information system functionality
              and business continuity.
            </p>

            <p className="incident-para">
              The incident response process includes continuous threat
              monitoring, an established response team, procedures for media
              inquiries, clear steps for identifying, responding, assessing,
              analysing, and follow-up of incidents, workforce training on
              incident response, and clear communication with internal and
              external stakeholders.
            </p>
          </div>

          <div>
            <h2 className="incident-head">Identification Phase</h2>
            <p className="incident-para">
              Campcard members promptly report suspected or known Precursors,
              Events, Indications, and Incidents through various channels,
              including direct management reporting, email, phone calls, online
              incident response form, secure chat, and anonymous workforce
              channels. Upon receipt, the Security Officer assesses and forwards
              non-technical issues for resolution, initiates investigations for
              minor infringements, assigns technical issues to IT resources, and
              activates the Security Incident Response Team (SIRT) for security
              incidents. The SIRT conducts investigations, implements
              preventative measures, and resolves incidents. Technical security
              incidents progress to Phase II: Containment, handled by qualified
              technical security resources under SIRT oversight. Documentation
              is maintained throughout each phase, culminating in a
              comprehensive Security Incident Report (SIR) summarizing all
              events, efforts, and conclusions. Notifications to affected
              Customers and Partners are made at the discretion of the Security
              and Privacy Officer.
            </p>
          </div>
          <div>
            <h2 className="incident-head">Containment Phase (Technical)</h2>
            <p className="incident-para">
              In this Phase, Campcard’s Tech department focuses on containing
              the security incident. Detailed notes are crucial for evidence in
              potential prosecution. The SIRT reviews collected information,
              secures the network perimeter, and instructs the Tech department
              to:
            </p>
            <ol className="ol-2" type="a">
              <li className="li-11">
                Securely connect to the affected system.
              </li>
              <li className="li-11">Retrieve volatile data.</li>
              <li className="li-11">
                Assess system integrity for potential backup.
              </li>
              <li className="li-11">If appropriate, back up the system.</li>
              <li className="li-11">Change affected system passwords.</li>
              <li className="li-11">
                Assess system safety for continued operations.
              </li>
              <li className="li-11">
                If safe, allow the system to function and document on the SIR
                Form.
              </li>
              <li className="li-11">Move to Phase V, Follow-up.</li>
            </ol>
            <p className="incident-para">
              If unsafe, discontinue system operations and proceed to Phase III,
              Eradication. The completion of this phase involves written
              communication to the SIRT, continuous updates to Senior
              Management, and ongoing notifications to affected Customers and
              Partners.
            </p>
          </div>

          <div>
            <h2 className="incident-head">Eradication Phase (Technical)</h2>
            <p className="incident-para">
              In the Eradication Phase, the SIRT aims to eliminate the cause and
              security exposures on affected system(s):
            </p>
            <ol className="ol-2" type="a">
              <li className="li-11">Identify symptoms and root cause.</li>
              <li className="li-11">
                Strengthen defenses around affected system(s), potentially
                through increased network and system monitoring defenses.
              </li>
              <li className="li-11">
                Remediate security issues within the affected system, including
                removing unused services and implementing host hardening
                techniques.
              </li>

              <li className="li-11">
                Conduct a comprehensive vulnerability assessment to address all
                exploitable holes/gaps.
              </li>

              <li className="li-11">
                Address any additional issues identified to prevent or minimize
                future compromises.
              </li>

              <li className="li-11">
                Complete the Eradication Form (see Appendix 4).
              </li>

              <li className="li-11">
                Update documentation with vulnerability assessment details.
              </li>

              <li className="li-11">Inform Senior Management of progress.</li>

              <li className="li-11">
                Notify affected Customers and Partners with relevant updates.
              </li>

              <li className="li-11">Move to Phase IV, Recovery</li>
            </ol>
          </div>

          <div>
            <h2 className="incident-head">Recovery Phase (Technical)</h2>
            <p className="incident-para">
              In the Recovery Phase, the SIRT focuses on restoring affected
              system(s) to operation:
            </p>
            <ol className="ol-2" type="a">
              <li className="li-11">
                The technical team assesses any changes to the affected
                system(s).
              </li>
              <li className="li-11">
                If changes are identified, the team restores the system to its
                intended functioning.
              </li>
              <li className="li-11">
                Validating the restored system's functionality involves
                coordination with the business unit.
              </li>
              <li className="li-11">
                If system operation was interrupted, restart and monitor for
                behavior.
              </li>
              <li className="li-11">
                If no changes occurred but the system was taken offline, restart
                and monitor for proper behavior.
              </li>

              <li className="li-11">
                Update documentation with phase details.
              </li>

              <li className="li-11">Inform Senior Management of progress.</li>

              <li className="li-11">
                Notify affected Customers and Partners with relevant updates.
              </li>

              <li className="li-11">Move to Phase V, Follow-up.</li>
            </ol>
          </div>
          <div>
            <h2 className="incident-head">
              Follow-Up Phase (Technical And Non-Technical)
            </h2>
            <p className="incident-para">
              In the Follow-up Phase, the SIRT and technical security resource
              review the security incident, extracting 'lessons learned' and
              assessing the process for potential improvements. Recommendations
              are documented, including cost and impact evaluations on Campcard.
              Findings are communicated to Senior Management for approval and
              subsequent implementation. Approved recommendations are executed
              with dedicated budget, time, and resources. The security incident
              is then officially closed.
            </p>
          </div>

          <div>
            <h2 className="incident-head">Close The Security Incident</h2>
            <p className="incident-para">
              Security incident response processes require periodic reviews for
              effectiveness. This includes training responders and the general
              population on Campcard’s security expectations. The incident
              response plan undergoes annual testing.
            </p>
          </div>

          <div>
            <h2 className="incident-head">
              Security Incident Response Team (SIRT)
            </h2>
            <p className="incident-para">
              The Security Incident Response Team (SIRT) comprises key
              individuals responsible for responding to security incidents,
              including the Security Officer, Head of Engineering, and Senior
              Management.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Incidentresponse;
