import React from "react";
import { MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";

const About = () => {
  
  document.title = "About-Us";
  return (
    <>
      <section id="about-5">
        <header className="header-container-10">
          <img
            src="./img/header-img-section.jpg"
            className="header-image-10"
            alt="HeaderImage"
          />
          <div className="header-text-10">
            {/* <p className="para-header-6"> About Us</p> */}
            <h2 className="header-head-10">About Us</h2>
          </div>
        </header>
      </section>

      <section id="about-1">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <img
                className="resize-about-1"
                src="./img/choose_us1.png"
                alt="about-1"
              />
            </div>
            <div class="col-md-6">
              <h2 className="heading10">Enabling Better Organisations</h2>
              <p className="para10">
                Our mission at Camp Card? To give HR / ADMIN teams time "to" focus
                on what really matters:"Employees". We help automate canteen
                processes and enhance employee satisfaction and free up time for
                more strategic work, enabling HR to go beyond HR.
              </p>

              <p className="para10">
                Since we started our journey, we’ve helped over 550 HR teams
                across India, Australia and the USA, focus less on admin and
                more on people. Also, let us help you bring in Savings,
                Transparency and Convenience in managing your company canteens.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="count">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-2 count-1">
              <img
                className="icon-50"
                src="./img/customers-icon-2.png"
                alt="customer"
              />
              <h2 className="icon-head-20">550+</h2>
              <p className="icon-para-20">Corporate Customers</p>
            </div>
            <div class="col-md-2 count-1">
              {" "}
              <img
                className="icon-50"
                src="./img/employees-icon.png"
                alt="employee"
              />
              <h2 className="icon-head-20">1.5Mn+</h2>
              <p className="icon-para-20">Daily Users</p>
            </div>
            <div class="col-md-2 count-1">
              {" "}
              <img
                className="icon-50"
                src="./img/canteen-icon.png"
                alt="canteen"
              />
              <h2 className="icon-head-20">1000+ </h2>
              <p className="icon-para-20">Canteens</p>
            </div>
            <div class="col-md-2 count-1">
              {" "}
              <img
                className="icon-50"
                src="./img/transaction-icon.png"
                alt="transaction"
              />
              <h2 className="icon-head-20">18Mn+</h2>
              <p className="icon-para-20">Transaction/Month </p>
            </div>
            <div class="col-md-2 count-1">
              {" "}
              <img
                className="icon-50"
                src="./img/location-icon.png"
                alt="location"
              />
              <h2 className="icon-head-20">250+ </h2>
              <p className="icon-para-20">Locations</p>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </section>

      <section id="story">
        <div className="container">
          <div className="main-story">
            <h2 className="story-head">Our Story</h2>
            <p className="story-para">
              Camp Card was founded in 2009 by Mani Shanker, Anand Pandey and Rekha Jha.
            </p>

            <p className="story-para">
              The founding team has its origins across FinTech and Hospitality
            </p>
          </div>
        </div>
      </section>

      <section id="timeline-55">
        <div className="container">
          <MDBContainer
            fluid
            className="py-5"
          
          >
            <div className="main-timeline">
              <div className="timeline left-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2023</h2> */}
                    <h2 className="month">October 2023</h2>
                    <p className="mb-0 best-description">
                    Atlanta, USA office launched
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>
              <div className="timeline right-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                    {/* <h2 className="year">2022</h2> */}
                    <h2 className="month">May 2022 </h2>
                    <p className="mb-0 best-description">
                    Moved to new office in Mumbai
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>
              <div className="timeline left-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2019</h2> */}
                    <h2 className="month">Jan 2019 </h2>
                    <p className="mb-0 best-description">
                    QR code and Face recognition based payment platform launched
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>
              <div className="timeline right-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2018</h2> */}
                    <h2 className="month">Oct 2018 </h2>
                    <p className="mb-0 best-description">
                    Reached 1 Mn daily users of the platform
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>
              <div className="timeline left-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2016</h2> */}
                    <h2 className="month">May 2016 </h2>
                    <p className="mb-0 best-description">
                      Sydney office launched
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>
              <div className="timeline right-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2014</h2> */}
                    <h2 className="month">June 2014 </h2>
                    <p className="mb-0 best-description">
                    eGifting platform launched
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>

              <div className="timeline left-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2012</h2> */}
                    <h2 className="month">Jan 2012 </h2>
                    <p className="mb-0 best-description">
                    Reached 1 lakh daily users of the platform 
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>

              <div className="timeline right-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2010</h2> */}
                    <h2 className="month">April 2010 </h2>
                    <p className="mb-0 best-description">
                    Contactless payment platform launched
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>

              <div className="timeline left-6">
                <MDBCard>
                  <MDBCardBody className="p-4">
                  {/* <h2 className="year">2009</h2> */}
                    <h2 className="month">December 2009  </h2>
                    <p className="mb-0 best-description">
                    Campcard was founded 
                    </p>
                  </MDBCardBody>
                </MDBCard>
              </div>

              


            </div>
          </MDBContainer>
        </div>
      </section>

      {/* <section id="cores">
        <div class="container text-center">
          <div class="row gap-3">
            <div class="col-md-3 cores-1 cores-2">
              <img src="./img/Mission.png" alt="mission" />
              <h2 className="heading11">Mission</h2>
              <p className="para11">
                Camp Card Solutions is a company devoted to providing innovative
                technology solutions for On-the-Go small value payments. Our
                mission is to help our customers with innovative payment
                solutions that helps enhance transparency through cashless /
                paperless payments. We helps our customers focus on larger
                issues concerning their respective field while supporting them
                with our solutions. Our motto is: Together, we believe that the
                best in each of us enriches all of us.
              </p>
            </div>
            <div class="col-md-3 cores-1 cores-3">
              <img src="./img/Vision.png" alt="Vision" />
              <h2 className="heading11">Vision</h2>
              <p className="para11">
                "To revolutionize the landscape of small value payments in India
                by providing innovative, reliable, and seamless contactless
                smart card-based On-the-Go payment solutions. Through leveraging
                decades of industry expertise and strategic collaborations with
                global leaders, our vision is to offer the fastest payment
                authorization system that overcomes infrastructural challenges.
                We aim to empower businesses and individuals alike, ensuring
                convenient, efficient, and secure transactions for all."
              </p>
            </div>
            <div class="col-md-3 cores-1 cores-2">
              <img src="./img/Values.png" alt="values" />
              <h2 className="heading11">Our Values</h2>
              <p className="para11">
                At Camp Card Solutions, our values form the bedrock of our
                operations. Integrity guides our every action, ensuring
                transparency and trust in all our endeavors.Customer-centricity
                is ingrained in our DNA, as we prioritize understanding and
                fulfilling the diverse needs of our clients. Collaboration is
                key; we believe in working hand in hand with our customers to
                achieve mutual success. Together, we strive to pave the way for
                a cashless, paperless society, where convenience and efficiency
                reign supreme.
              </p>
            </div>

            <div class="col"></div>
          </div>
        </div>
      </section> */}

      {/* <section id="working">
        <h2 className="heading3">Our Working Process</h2>

        <div class="container text-center">
          <div class="row ">
            <div class="col-md-2 working-1">
              <div className="circle">
                <img
                  className="resize-working"
                  src="./img/Research.png"
                  alt="Research"
                />
              </div>
              <h2 className="heading4">Research & Explore</h2>
              <p className="para2">
                An online quote is an estimate of the cost of a product or
                service provided.
              </p>
            </div>
            <div class="col-md-2 working-1">
              <div className="circle">
                <img
                  className="resize-working"
                  src="./img/comparison.png"
                  alt="comparison"
                />
              </div>

              <h2 className="heading4">Comparison Tools</h2>
              <p className="para2">
                Picking a product refers to the process a specific from a range.
              </p>
            </div>
            <div class="col-md-2 working-1">
              <div className="circle">
                <img
                  className="resize-working"
                  src="./img/reviews.png"
                  alt="reviews"
                />
              </div>
              <h2 className="heading5">Reviews & Feedback</h2>
              <p className="para2">
                Product packaging refers to the materials and design used to
                protect.
              </p>
            </div>
            <div class="col-md-2 working-1">
              <div className="circle">
                <img
                  className="resize-working"
                  src="./img/resourse.png"
                  alt="resourse"
                />
              </div>
              <h2 className="heading5">Secure Your Payment</h2>
              <p className="para2">
                Product transport refers to the process of products from
                location.
              </p>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </section>
 */}
    </>
  );
};

export default About;
