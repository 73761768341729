import React from "react";
import "../App.css";
import { Link } from "react-router-dom";

const Footer = () => {

  // facebook link

  const openFacebook = () => {
    window.open('https://www.facebook.com/campcardsolutions/wall/', '_blank');
  };

  // linkedIn link


  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/company/camp-card-solutions-p-ltd', '_blank');
  };

  // instagram link


  const openInstagram = () => {
    window.open('https://www.instagram.com', '_blank');
  };

  // youtube link


  const openYoutube = () => {
    window.open('https://www.youtube.com/@campcardsolutions2753', '_blank');
  };

  const handleEmailClick = () => {
    window.location.href = "https://www.google.com/gmail";
  };

  const phoneNumber = '022 66 737 300';

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
    

      <section id="footer-1">
        <img
          className="resize-footer-1"
          src="./img/footer-1.png"
          alt="footer"
        />
      </section>

    
      <section id="footer-2">
        <div class="container ">
          <div class="row">
            <div class="col-md-4">
              <img className="camp-footer-logo" src="./img/camp-logo.png" alt="logo" />
            </div>
            <div class="col-md-4 policy-top">
              {/* <h2 className="policy-head">Policy</h2> */}
              <p>
                <Link to="/privacypolicy" className="para-policy" onClick={scrollToTop}>
                  Privacy And Policy
                </Link>
              </p>
              <p>
                <Link to="/termcondition" className="para-policy"  onClick={scrollToTop}>
                  Terms And Conditions
                </Link>
              </p>
              <p>
                <Link to="/incidentresponsepolicy" className="para-policy" onClick={scrollToTop}>
                  Incident Response Policy
                </Link>
              </p>
            </div>
            <div class="col-md-4">
              {/* <h2 className="heading-30">Contact Us</h2> */}

              <div className="first-icon-1">
                <div className="section-icon-4">
                  <i class="fa fa-phone phone-icon-3"></i>
                </div>

                <div className="section-para-4">
                  <p className="para-110"> 
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </p>
                </div>
              </div>

              <div className="second-icon-1">
                <div className="section-icon-4">
                  <i class="fa fa-map-marker location-icon-3"></i>
                </div>

                <div className="section-para-4">
                  <p className="para-110">
                    1215B, Lodha Supremus, Kolshet Rd, Thane, Maharashtra 400607
                  </p>
                </div>
              </div>

              <div className="third-icon-1">
                <div className="section-icon-4">
                  <i class="fa fa-envelope email-icon-3"></i>
                </div>

                <div className="section-para-4">
                  <p className="para-112">
                  <a
                    href="mailto:sales@campcardsolutions.com"
                    onClick={handleEmailClick}
                  >
                    sales@campcardsolutions.com
                  </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="social-icons-2">
          <div className="container">
          <i class="fab fa-facebook-square social-icons-3" onClick={openFacebook}></i>
          <i class="fab fa-linkedin social-icons-3" onClick={openLinkedIn}></i>
          <i class="fab fa-instagram social-icons-3" onClick={openInstagram}></i>
          <i class="fab fa-youtube social-icons-3" onClick={openYoutube}></i>
          </div>
        </div>
      </section>


      <section id="footer-3">
        <div className="container final-footer">
          <div className="left-1">
            <p className="para-70">
              &copy; 2024 Camp Card | All Right Reserved
            </p>
          </div>

          {/* <p className="para-71">Designed By Ossis Infotech</p> */}
        </div>
      </section>
    </>
  );
};

export default Footer;
