

import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import "../App.css";

const MyNavbar = () => {
  // const [showSubMenu, setShowSubMenu] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  const handleCustomerLogin = () => {
    window.location.href = "https://adm.campcardsolutions.org/"; // Redirect to Customer login
    
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <section id="navi">
      <Navbar expand="lg" expanded={expanded} onToggle={handleToggle}>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="./img/camcard-logo-new.png"
              className="d-inline-block align-top logo-resize"
              alt="Logo"
              onClick={handleLinkClick}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="flex-sm-column toggle-change"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto right-10">
              <Nav.Link
                className="right-3"
                style={{ color: "black" }}
                as={Link}
                to="/"
                onClick={handleLinkClick}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className="right-3"
                style={{ color: "black" , }}
                as={Link}
                to="/about"
                onClick={handleLinkClick}
              >
                About
              </Nav.Link>

              <Nav.Link
                style={{ color: "black" }}
                as={Link}
                to="/products"
                onClick={handleLinkClick}
                // onMouseEnter={() => setShowSubMenu(true)}
                // onMouseLeave={() => setShowSubMenu(false)}
              >
                Product
              </Nav.Link>
              {/* <NavDropdown
                id="basic-nav-dropdown"
                className="product-title"
                show={showSubMenu}
                onMouseEnter={() => setShowSubMenu(true)}
                onMouseLeave={() => setShowSubMenu(false)}
              >
                <NavDropdown.Item as={Link} to="/smartmealcard">
                  Smart Meal Card
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/egiftcoupons">
                  eGift Coupons
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/timeattendancesystem">
                  Time Attendance System
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/visitormanagement">
                  Visitor Management
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/idcardprinting">
                  ID Card Printing
                </NavDropdown.Item>
              </NavDropdown> */}

              <Nav.Link
                className="right-4"
                style={{ color: "black" }}
                as={Link}
                to="/career"
                onClick={handleLinkClick}
              >
                Career
              </Nav.Link>

              <Nav.Link
                className="right-4"
                style={{ color: "black" }}
                as={Link}
                to="/blog"
                onClick={handleLinkClick}
              >
                Blog
              </Nav.Link>
              <Nav.Link
                className="right-5"
                style={{ color: "black" }}
                as={Link}
                to="/contact"
                onClick={handleLinkClick}
              >
                Contact
              </Nav.Link>

              {/* <button className="login-btn">Customer Login</button> */}
              <Link to="#" className="login-btn" onClick={handleCustomerLogin}>
                Customer Login
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
};

export default MyNavbar;
