import React from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

const Visitormanagement = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [comapny, setCompany] = useState("");
  const [selectedproduct, setSelectedProduct] = useState("");

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      // Checks if input consists of 10 digits or less
      setNumber(inputValue);
      if (inputValue.length !== 10) {
        setError("Number must be exactly 10 digits long");
      } else {
        setError("");
      }
    }
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "service_k6lmbm8";
    const templateId = "template_tq55tcc";
    const publicKey = "kHo1cvgHPOMZrnrRB";

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_number: number,
      from_email: email,
      from_company: comapny,
      from_product: selectedproduct,

      to_name: "",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setName("");
        setNumber("");
        setEmail("");
        setCompany("");
        setMessage("");
        setSelectedProduct("");
      })

      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };
  return (
    <>
      <section id="about-5">
        <header className="header-container">
          <img
            src="./img/card-3.jpg"
            className="header-image"
            alt="Header Image"
          />
          <div className="header-text">
            <p className="para-51">Visitor Management</p>
          </div>
        </header>
      </section>

      <section id="visitor-manage">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <p className="visitor-para">
                Our Visitor Management System goes beyond traditional check-in
                processes to provide a seamless and memorable experience for
                visitors and hosts alike. With intuitive features and advanced
                technology, we elevate every aspect of the visitor journey,
                starting from pre-registration. Our system allows hosts to
                pre-register visitors, saving time and ensuring a smoother
                check-in process upon arrival.
              </p>

              <p className="visitor-para-1">
                Once visitors step through the door, they are greeted with
                efficiency and professionalism. Our streamlined check-in process
                minimizes wait times, allowing visitors to quickly proceed to
                their intended destination. Enhanced security measures,
                including badge printing and access controls, provide peace of
                mind for both hosts and visitors, ensuring a secure environment
                at all times.
              </p>
            </div>
            <div class="col-md-6">
              <img
                className="resize-visitor-mana"
                src="./img/visitor-mana.jpg"
                alt="visitor"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="features">
        <h2 className="features-heading">Visitor Management Features</h2>

        <div class="container text-center">
          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                One second to avoid queuing at counters
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                Contact less smart card or your compatible ID card or mobile
                phone can also be used instead of the card
              </p>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                {" "}
                Mobile terminals to allow serving at employees’ work station /
                conference rooms
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                Can work in both prepaid / Post paid mode
              </p>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                {" "}
                Daily or monthly usage limits can be set
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">Multiple menus for easy transaction</p>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                {" "}
                Paper less transaction though system has provision for printing
                receipts
              </p>
            </div>
            <div class="col-md-6 smc-10">
              <p className="smc-para">
                Customised setup for permanent, contract or guest users
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="application">
        <h2 className="appli-heading">Visitor Management Benefits</h2>

        <div class="container ">
          <div class="row">
            <div class="col-md-3 smart-flip">
              {" "}
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    {/* <i class="fas fa-user user-icon"></i> */}
                    <i class="fas fa-building building-icon"></i>
                    <h2 className="flip-head">Corporate</h2>
                  </div>
                  <div className="flip-card-back">
                    <ul className="ul-2">
                      <li className="li-2">
                        Prevents misuse of meal allowance thereby optimizing
                        canteen expenditure.
                      </li>

                      <li className="li-2">
                        Easy employee subsidy calculation and audit basis online
                        reports.
                      </li>

                      <li className="li-2">
                        Fully managed service thereby complete peace of mind.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 smart-flip">
              {" "}
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <i class="fas fa-store store-icon"></i>
                    <h2 className="flip-head">Merchant</h2>
                  </div>
                  <div className="flip-card-back">
                    <ul className="ul-2">
                      <li className="li-2">
                        Online remote monitoring to prevent cash pilferage by
                        staff.
                      </li>

                      <li className="li-2">
                        Quick and easy bill preparation at the end of the month.
                      </li>

                      <li className="li-2">
                        Fully managed service thereby complete peace of mind.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 smart-flip">
              {" "}
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    {/* <i class="fas fa-user-tie user-icon"></i> */}
                    <i class="fa fa-users user-icon"></i>
                    <h2 className="flip-head">Employee</h2>
                  </div>
                  <div className="flip-card-back">
                    <ul className="ul-2">
                      <li className="li-2">Fast and easy payments</li>

                      <li className="li-2">
                        Unlike paper instruments, can block card and avoid loss
                        when card is misplaced.
                      </li>

                      <li className="li-2">
                        Can access usage data online and claim tax benefit.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </section>

      <section id="enquiry">
        <header className="header-container-1">
          <img
            src="./img/enquiry-banner.jpg"
            className="header-image-1"
            alt="enquiryImage"
          />
          <div className="header-text-1">
            <h2 className="en-head">
              We are here to answer your questions 24/7
            </h2>
            {/* <p className="en-para">Need A Enquiry</p> */}
            <button className="en-btn" onClick={handleButtonClick}>
              Enquiry Now
            </button>
          </div>
        </header>

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <h2 className="en-head-2">Enquiry Form</h2>
              <form onSubmit={handleSubmit} className="emailForm">
                <input
                  className="input-3"
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />{" "}
                <br />
                <input
                  className="input-3"
                  type="number"
                  placeholder="Your Number"
                  value={number}
                  required
                  onChange={handleChange}
                />{" "}
                {error && <p style={{ color: "red" }}>{error}</p>}
                <br />
                <input
                  className="input-3"
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />{" "}
                <br />
                <input
                  className="input-3"
                  type="text"
                  placeholder="Your Company name"
                  value={comapny}
                  required
                  onChange={(e) => setCompany(e.target.value)}
                />{" "}
                <br />
                <select
                  className="input-3"
                  value={selectedproduct}
                  required
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="">Select a Product List</option>
                  <option value="Smart Meal Card">Smart Meal Card</option>
                  <option value="Egift Coupons">EGift Coupons</option>
                  <option value="Time Attendance System">
                    Time Attendance System
                  </option>
                  <option value="Visitor Management">Visitor Management</option>

                  <option value="Id Card Printing">Id Card Printing</option>
                </select>
                <br />
                <textarea
                  className="textarea-3"
                  placeholder="Message"
                  cols="30"
                  rows="10"
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>{" "}
                <br />
                <button type="submit" className="btn-email-pop">
                  Send Email
                </button>
              </form>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Visitormanagement;
