const API_URL = 'https://campmailapi.skokk.co.in';
// const API_URL = 'http://localhost:5000';


async function httpSendMailwithFile(endpoint, params) {
  try{
    return await fetch(`${API_URL}/${endpoint}`, {
      method : "POST",
      body : params,
    });
  } catch(err){
    return { ok:false }
  }
}

async function httpSendMail(endpoint, params) {
  try{
    console.log(params);
    return await fetch(`${API_URL}/${endpoint}`, {
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(params),
    });
  } catch(err){
    return { ok:false }
  }
}

export {
  httpSendMail, httpSendMailwithFile
};