import React from "react";
// import Slider from "react-slick";


// import { Carousel } from "antd";



const Blog = () => {

  document.title = "Blog";
 
  return (
    <>
      <section id="about-5">
        <header className="header-container-10">
          <img
            src="./img/header-img-section.jpg"
            className="header-image-10"
            alt="HeaderImage"
          />
          <div className="header-text-10">
            {/* <p className="para-header-6"> About Us</p> */}
            <h2 className="header-head-10">Blog</h2>
          </div>
        </header>
      </section>

      <section id="blogsonly">
        <h2 className="blogs-head">Coming Soon</h2>
      </section>

      

      
    </>
  );
};

export default Blog;
