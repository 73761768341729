import React from "react";
import { Link } from "react-router-dom";

const Allproducts = () => {

  document.title="Products"
  return (
    <>
       <section id="about-5">
      <header className="header-container-10">
        <img
          src="./img/header-img-section.jpg"
          className="header-image-10"
          alt="HeaderImage"
        />
        <div className="header-text-10">
          {/* <p className="para-header-6"> About Us</p> */}
          <h2 className="header-head-10">Products</h2>
        </div>
      </header>

      </section>

      <section id="products-1">
        <div className="container">
          <h2 className="heading-17">Products</h2>
        </div>
      </section>

      <section id="product-40">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6 smart-1">
              <h2 className="smc1-1">Card Based Cafeteria System </h2>

              <p className="para-16">
                <ul className="ul-1">
                  <li className="li-1">
                    ID card with cafeteria payments
                  </li>
                  <li className="li-1">
                    Contactless flash and go payments
                  </li>
                  <li className="li-1">
                  	Offline capability for un-interrupted operations
                  </li>
                  <li className="li-1">
                  	Customized reports as per your company’s meal policy
                  </li>
                  <li className="li-1">
                  	ID card based vending machine payments
                  </li>

                  <li className="li-1">	Easy deployment with no infrastructure required from client</li>

                  <li className="li-1">
                	Rental model for guaranteed success
                  </li>

                  <li className="li-1">
                  	Over 500 backend features for addressing your changing requirements
                  </li>
                </ul>
              </p>
              {/* <Link to="/smartmealcard" className="btn-4">
                Read More
              </Link> */}
            </div>
            <div class="col-md-6 smart-1">
              <img
                className="resize-product"
                src="./img/product-1.png"
                alt="card pos"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="product-41">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <img
                className="resize-product"
                src="./img/product-2.png"
                alt="qrcode"
              />
            </div>
            <div class="col-md-6">
              <h2 className="smc1-1">	QR Code Based Payments </h2>
              <p className="para-16">
                <ul className="ul-1">
                  <li className="li-1">
                  	Employee app for scanning caterer’s QR code
                  </li>
                  <li className="li-1">
                   	Caterer app to scan employee’s QR code
                  </li>

                  <li className="li-1">	Offline capability for un-interrupted operations</li>

                  <li className="li-1">	Customized reports as per your company’s meal policy</li>

                  <li className="li-1">
                   	Easy deployment with no infrastructure required from client
                  </li>

                  <li className="li-1">
                  	Rental model for guaranteed success
                  </li>

                  <li className="li-1">
                  	Over 500 backend features for addressing your changing requirements
                  </li>
                </ul>
              </p>
              {/* <Link to="/egiftcoupons" className="btn-4">
                Read More
              </Link> */}
            </div>
          </div>
        </div>
      </section>

      <section id="product-40">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <h2 className="smc1-1">	Face Scan In Cafeteria For Payments </h2>
              <p className="para-16">
                <ul className="ul-1">
                  <li className="li-1">
                 	Caterer app to scan employee’s face
                  </li>
                  <li className="li-1">
                  	Offline capability for un-interrupted operations
                  </li>
                  <li className="li-1">
                  	Customized reports as per your company’s meal policy
                  </li>
                  <li className="li-1">
                  	Easy deployment with no infrastructure required from client
                  </li>
                  <li className="li-1">
                  	Rental model for guaranteed success
                  </li>
                  <li className="li-1">
                  	Over 500 backend features for addressing your changing requirements
                  </li>
                </ul>
              </p>

              {/* <Link to="/timeattendancesystem" className="btn-4">
                Read More
              </Link> */}
            </div>
            <div class="col-md-6">
              <img
                className="resize-product"
                src="./img/product-3.png"
                alt="time"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="product-41">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <img
                className="resize-product"
                 src="./img/product-4.png"
                alt="visitor"
              />
            </div>
            <div class="col-md-6">
              <h2 className="smc1-1">Employee App For Convenient Pre-Payment </h2>
              <p className="para-16">
              <ul className="ul-1">
                <li className="li-1">	Order and pay before visiting cafeteria</li>
                <li className="li-1">	Choose caterer and menus sitting from comfort of your desk</li>

                <li className="li-1">Prepaid and post-paid mode</li>

                {/* <li className="li-1">Real-time monitoring of visitor movements for safety purposes.</li>

                <li className="li-1">Compliance with regulatory requirements and data protection laws.</li>

                <li className="li-1">Integration with access control systems for enhanced premises security.</li>

                <li className="li-1">Customization options for tailored visitor experiences.</li> */}

              </ul>
              </p>
              {/* <Link to="/visitormanagement" className="btn-4">
                Read More
              </Link> */}
            </div>
          </div>
        </div>
      </section>

      <section id="product-40">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <h2 className="smc1-1">	Snack And Beverage Vending Machines </h2>
              <p className="para-16">
               <ul className="ul-1">
                <li className="li-1">
                	Get vending machines on rent
                </li>
                <li className="li-1">
                 	ID card /  UPI / credit card based payments
                </li>
                <li className="li-1">
                	Online reporting
                </li>
                
         
               </ul>
              </p>
              {/* <Link to="/idcardprinting" className="btn-4">
                Read More
              </Link> */}
            </div>
            <div class="col-md-6">
              <img
                className="resize-product"
                src="./img/product-5.png"
                alt="coffee"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Allproducts;
