import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { httpSendMail } from '../hooks/requests';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import "swiper/css";
import "swiper/css/pagination";

const Home = () => {
  
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");

  const MySwal = withReactContent(Swal);

  const handleChange = (e) => {
    
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      // Checks if input consists of 10 digits or less
      setNumber(inputValue);
      if (inputValue.length !== 10) {
        setError("Number must be exactly 10 digits long");
      } else {
        setError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    MySwal.showLoading();
    const response = await httpSendMail('sendletstalkmail', {number:number});

    setNumber("");

    const success = response.ok;

    if(success){
      console.log('request sent');
      MySwal.fire({
        // title: "Mail sent successfully! We will get back to you!",
        title: "We will get back to you soon",
        icon: "success"
      });
    }
    else{
      console.log('request failed');
      MySwal.fire({
        title: "Failed to send mail!",
        icon: "error"
      });
    }
    
  };

  const swiperRef = React.useRef(null);

  useEffect(() => {
    const swiper = document.querySelector(".mySwiper");
    if (swiper && swiper.swiper) {
      swiper.swiper.autoplay.start();
    }
  }, []);

  // document.title = "India’s leading provider of cashless cafeteria solution";

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const products = [
    {
      id: 1,
      image: "img/jsw-logo-2.png",
      // description:
      //   "Introducing Smart Meal Card – an innovative electronic coupon system designed.",
    },
    {
      id: 2,
      image: "img/hyundai-motor-logo.png",
      // description:
      //   "Introducing eGift Coupons – the hassle-free solution for gifting.",
    },
    {
      id: 3,

      image: "img/niit_logo.png",
      // description:
      //   "Introducing our cutting-edge Biometric Time-Attendance System, designed to automate. ",
    },
    {
      id: 4,

      image: "img/tata-steel-50.webp",
      // description:
      //   "Elevate your visitor experience from the moment they step through the door. ",
    },
    {
      id: 5,

      image: "img/tata-logo-50.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    {
      id: 6,

      image: "img/posco-logo-2.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    {
      id: 7,

      image: "img/hitachi-logo.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    {
      id: 8,

      image: "img/induslnd-bank-logo.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    {
      id: 9,

      image: "img/crompton-logo.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    {
      id: 10,

      image: "img/blue-star-logo-1.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    {
      id: 11,

      image: "img/ipower-logo.png",
      // description:
      //   "Rely on the expertise of our skilled team of professionals.",
    },

    //Add more products as needed...
  ];

  // const testimonialSettings = {
  //   autoplay: true,
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,

  //       },
  //     },
  //   ],
  // };

  // const testimonials = [
  //   {
  //     id: 1,
  //     name: "Raosaheb Darekar",
  //     content:
  //       "CampCard 's system is innovative & provides complete peace of mind on cafeteria. Their service levels are exemplary. They helped us bring down our canteen expense by over 20%...",
  //     image: "img/raosaheb-darekar.jpg",
  //     title: "Head HR - Blue Star",
  //   },

  //   {
  //     id: 2,
  //     name: "Ashok Prabhakar",
  //     content:
  //       "A single card that helps users pay in canteen as well as for company bus usage. Ideal and cost effective system for managing company bus usage and administering subsidised meals…",
  //     image: "img/ashok-prabhakar.jpg",
  //     title: "Head Admin - NIIT",
  //   },

  //   {
  //     id: 3,
  //     name: "Sanjay Anand",
  //     content:
  //       "Their system and equipments are robust and has features to address challenges of canteen setup.Their system is hassle free transparent and helped us save over 35% on our canteen.",
  //     image: "img/sanjay-anand.jpg",
  //     title: "Factory Head - ISMT",
  //   },

  //   {
  //     id: 4,
  //     name: "Shreenath Mudholkar",
  //     content:
  //       "I like their wireless system which is easy to install, is flexible and helps solve issues associated with industrial canteen. Their card doubles up as our ID and access card too. ",
  //     image: "img/shreenath-mudholkar.jpg",
  //     title: "Factory Manager - Kirloskar ",
  //   },

  // ];

  return (
    <>
      <section id="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>India’s leading provider of cashless cafeteria solution</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
          <meta
            name="keywords"
            content="canteen management system, meal card, cafeteria automation, cashless payments, canteen card, prepaid card, corporate gifting, gift vouchers, meal vouchers"
          />
        </Helmet>
        <Carousel controls={false} interval={3000}>
          <Carousel.Item className="slide-smooth">
            <div className="container">
              <div className="row">
                <div className="col-md-6 top-change">
                  <div className="slider-content">
                    <h2 className="heading-top">
                      Pioneers and India’s leaders in
                    </h2>
                    <h2 className="heading-second">
                      “On-the-Go” payment system.
                    </h2>
                    <h2 className="heading-top">
                      Ideal technology for cashless campus payments.
                      {/* &nbsp; */}
                    </h2>

                    <form onSubmit={handleSubmit} className="emailForm">
                      <input
                        className="input-90"
                        type="number"
                        placeholder="Number"
                        value={number}
                        required
                        onChange={handleChange}
                      />
                      <button className="talk-4">Let's Talk</button>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="d-block banner-55 "
                    src="./img/slider-20.png"
                    alt="Right SideImage"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className="slide-smooth">
            <div className="container">
              <div className="row">
                <div className="col-md-6 top-change">
                  <div className="slider-content">
                    {/* <h2 className="heading-top-1">Delight your employees </h2> */}
                    <h2 className="heading-top-1">
                      Integrated Tea -Coffee & Snack vending machines with
                    </h2>

                    <h2 className="heading-second-1">Cashless Payments </h2>

                    <h2 className="heading-top-1">
                      for anytime snacking beyond cafeteria.
                    </h2>

                    <form onSubmit={handleSubmit} className="emailForm">
                      <input
                        className="input-90"
                        type="number"
                        placeholder="Number"
                        value={number}
                        required
                        onChange={handleChange}
                      />
                      <button className="talk-4">Let's Talk</button>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="d-block banner-55 "
                    src="./img/slider-21.png"
                    alt="Right SideImage"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className="slide-smooth">
            <div className="container">
              <div className="row">
                <div className="col-md-6 top-change">
                  <div className="slider-content">
                    <h2 className="heading-top-2">
                      Provide freedom of choice to your employees.
                    </h2>

                    <h3 className="heading-second-2">
                      Pay using ID Card, QR Code,Employee App or Face Scan
                    </h3>

                    {/* &nbsp; */}

                    <form onSubmit={handleSubmit} className="emailForm">
                      <input
                        className="input-90"
                        type="number"
                        placeholder="Number"
                        value={number}
                        required
                        onChange={handleChange}
                      />{" "}
                      <button className="talk-4">Let's Talk</button>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="d-block banner-55 "
                    src="./img/slider-22.png"
                    alt="Right SideImage"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className="slide-smooth">
            <div className="container">
              <div className="row">
                <div className="col-md-6 top-change">
                  <div className="slider-content">
                    <h2 className="heading-top-3">
                      Secure plug-n-play setup. Suitable for all kinds of
                      organisations.
                    </h2>

                    <h3 className="heading-second-3">
                      Small or Large | Factories or Offices | Hospitals or
                      Schools.
                    </h3>

                    {/* &nbsp; */}

                    <form onSubmit={handleSubmit} className="emailForm">
                      <input
                        className="input-90"
                        type="number"
                        placeholder="Number"
                        value={number}
                        required
                        onChange={handleChange}
                      />{" "}
                      <button className="talk-4">Let's Talk</button>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="d-block banner-55 "
                    src="./img/slider-23.png"
                    alt="Right SideImage"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className="slide-smooth">
            <div className="container">
              <div className="row">
                <div className="col-md-6 top-change">
                  <div className="slider-content">
                    {/* &nbsp; */}

                    <h2 className="heading-top-4">Customised Reporting for </h2>

                    <h2 className="heading-second-4">Hassle Free Payroll,</h2>

                    <h2 className="heading-top-4">
                      Audit and Caterer payments.{" "}
                    </h2>

                    <form onSubmit={handleSubmit} className="emailForm">
                      <input
                        className="input-90"
                        type="number"
                        placeholder="Number"
                        value={number}
                        required
                        onChange={handleChange}
                      />{" "}
                      <button className="talk-4">Let's Talk</button>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </form>

                    {/* <h3></h3> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="d-block banner-55 "
                    src="./img/slider-24.png"
                    alt="Right SideImage"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>

      {/* choose us*/}

      <section id="choose">
        <div class="container ">
          <div class="row">
            <div class="col-md-6 ">
              <img
                className="choose-resize"
                src="./img/choose_us2.png"
                alt="choose-us"
              />
            </div>
            <div class="col-md-6">
              <h2 className="heading1">What More?</h2>

              <p className="para1">
                <ul className="ul-5">
                  <li className="li-4">
                    {" "}
                    Choose from a host of empaneled caterers
                  </li>
                  <li className="li-4">
                    {" "}
                    Outsource your ID card issuance- Single card with integrated
                    canteen and vending machines payments
                  </li>

                  <li className="li-4"> Get the entire system on rent</li>

                  <li className="li-4">
                    {" "}
                    Help your employees save tax through our eGifting solution
                  </li>
                </ul>
              </p>

              {/* <Link to="/about" className="btn-1">
                Read More
              </Link> */}
            </div>
          </div>
        </div>
      </section>

      {/* Management*/}

      <section id="management">
        <h2 className="heading-40">
          We are India’s leading canteen management company serving <br /> for
          the past 15 years
        </h2>

        <img
          className="resize-management"
          src="./img/map-8.jfif"
          alt="management"
        />
      </section>

      <section id="products">
        <h2 className="heading2">Serving Employees Of</h2>

        <div className=" container product-gallery">
          <Slider {...settings}>
            {products.map((product) => (
              <div key={product.id} className="product-item">
                <img
                  className="product-55"
                  src={product.image}
                  alt={product.name}
                />
                {/* <h2 className="heading-60">{product.name}</h2>
                <p className="para-60">{product.description}</p> */}
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* testimonials */}

      <section id="testimonials">
        <h2 className="heading6">What Client Say</h2>
        <div className="container ">
          <Swiper
            ref={swiperRef}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return `<span class="${className} custom-pagination-bullet"></span>`;
              },
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
            autoplay={{
              delay: 3000, // time between transitions in ms
            }}
            breakpoints={{
              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              // when window width is <= 768px (mobile screens)
              767: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <p className="testimonial-para-50">
                CampCard 's system is innovative & provides complete peace of
                mind on cafeteria. Their service levels are exemplary. They
                helped us bring down our canteen expense by over 20%...
              </p>

              <div className="testimonial-main-50">
                <div className="testimonial-sub-50">
                  <img
                    className="client-pic"
                    src="./img/raosaheb-darekar.jpg"
                    alt="RaoSaheb"
                  />
                </div>

                <div className="testimonial-sub-51">
                  <h2 className="testimonial-head-50">Raosaheb Darekar</h2>
                  <h3 className="testimonial-head-51">Head HR - Blue Star</h3>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <p className="testimonial-para-50">
                A single card that helps users pay in canteen as well as for
                company bus usage. Ideal and cost effective system for managing
                company bus usage and administering subsidised meals…
              </p>

              <div className="testimonial-main-50">
                <div className="testimonial-sub-50">
                  <img
                    className="client-pic"
                    src="./img/ashok-prabhakar.jpg"
                    alt="Ashok"
                  />
                </div>

                <div className="testimonial-sub-51">
                  <h2 className="testimonial-head-50">Ashok Prabhakar</h2>
                  <h3 className="testimonial-head-51">Head Admin - NIIT </h3>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <p className="testimonial-para-50">
                Their system and equipments are robust and has features to
                address challenges of any type of canteen setup. Their system is
                hassle free , transparent and helped us save over 35% on our
                canteen expense.
              </p>

              <div className="testimonial-main-50">
                <div className="testimonial-sub-50">
                  <img
                    className="client-pic"
                    src="./img/sanjay-anand.jpg"
                    alt="Sanjay"
                  />
                </div>

                <div className="testimonial-sub-51">
                  <h2 className="testimonial-head-50">Sanjay Anand</h2>
                  <h3 className="testimonial-head-51">Factory Head - ISMT</h3>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <p className="testimonial-para-50">
                I like their wireless system which is easy to install, is
                flexible and helps solve issues associated with industrial
                canteen. Their card doubles up as our ID and access card too.
                Their customised reporting is a big plus…
              </p>

              <div className="testimonial-main-50">
                <div className="testimonial-sub-50">
                  <img
                    className="client-pic"
                    src="./img/shreenath-mudholkar.jpg"
                    alt="RaoSaheb"
                  />
                </div>

                <div className="testimonial-sub-51">
                  <h2 className="testimonial-head-50">Shreenath Mudholkar</h2>
                  <h3 className="testimonial-head-51">
                    Factory Manager-Kirloskar
                  </h3>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                  <i class="fas fa-star icon-star-5"></i>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide> */}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Home;
