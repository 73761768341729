import React, { useEffect } from "react";

const Termcondition = () => {

    document.title = "Terms And Conditions";

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
     <section id="about-5">
      <header className="header-container-10">
        <img
          src="./img/header-img-section.jpg"
          className="header-image-10"
          alt="HeaderImage"
        />
        <div className="header-text-10">
          {/* <p className="para-header-6"> About Us</p> */}
          <h2 className="header-head-10">Terms and Conditions</h2>
        </div>
      </header>

      </section>

      <section id="policy-21">
        <div className="container">
          <ol className="ol-1">
            <li className="li-10">
              The Terms of Use apply to the Campcard website, our application
              for cafeteria devices, personal handheld devices, and other food
              management services. By using the services, downloading the
              application, or ordering food, you agree to these terms.
            </li>

            <li className="li-10">
              The Services, under the brand Campcard, are owned and operated by
              Camp Card Solutions Pvt Ltd., referred to as 'Campcard,' 'we,'
              'us,' or 'our.
            </li>

            <li className="li-10">
              We are a Food Technology company offering solutions to modernize
              employee food experiences through our website, mobile
              applications, and cafeteria tablet application. We integrate
              cafeteria vendors via a vendor application for managing employee
              orders. It's important to note that we do not own or control the
              services provided by the vendors.
            </li>

            <li className="li-10">
              We reserve the right to change the Terms of Use without notice,
              and you are responsible for staying updated with any changes
              through this section.
            </li>

            <li className="li-10">
              You are responsible for paying fees for lost Food Cards.
            </li>

            <li className="li-10">
              You must keep your login credentials private and not share them.
              We are not responsible for the wallet balance if others use your
              account.
            </li>

            <li className="li-10">
              We reserve the right to terminate your account without notice
              based on input from your employer.
            </li>

            <li className="li-10">
              All orders on Campcard are considered confirmed and cannot be
              cancelled or transferred to another vendor as a standard practice.
            </li>

            <li className="li-10">
              You can recharge your account through cash injection or online
              recharges (if applicable), and you are responsible for the terms
              and conditions of the payment gateway used for the recharge.
            </li>

            <li className="li-10">
              A refund is only available if the vendor cancels your order due to
              the unavailability of the food item or for reasons stated by the
              vendor.
            </li>

            <li className="li-10">
              You acknowledge that we are not responsible for the services or
              goods provided by the vendor, including any issues arising from
              the consumption of food.
            </li>

            <li className="li-10">
              Services are only for individuals aged 18 or older. If you are
              under 18, you may use our services, but we hold no responsibility.
            </li>

            <li className="li-10">
              You agree to receive SMSs and emails from Campcard regarding order
              intimations and updates.
            </li>

            <li className="li-10">
              You agree not to use the services for any commercial, unlawful, or
              harmful activities. Any such activities are solely attributed to
              the individual, with no blame or responsibility assigned to
              Campcard.
            </li>

            <li className="li-10">
              You agree to handle provided hardware (cafeteria devices, vendor
              mobiles) with care. We reserve the right to recover damages if
              found guilty by the employer or Campcard support.
            </li>

            <li className="li-10">
              We own the intellectual property for all our services, governed by
              applicable copyright laws.
            </li>

            <li className="li-10">
              . We own the Vendor application, cafeteria application, mobile
              applications for Android and iOS, and the website (desktop and
              mobile). Any attempt to destroy, imitate, or harm these
              applications will be considered a breach of the Terms of Use.
            </li>
          </ol>
        </div>
      </section>
    </>
  );
};

export default Termcondition;
