import React, { useRef } from "react";
//import emailjs from "@emailjs/browser";
import { useState } from "react";
import { httpSendMail } from '../hooks/requests';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Contact = () => {
  document.title = "Contact";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [userType, setUserType] = useState("organisation");
  const [other, setOther] = useState("");

  const [error, setError] = useState("");
  const [cityName, setCityName] = useState("");

  const [activeButton, setActiveButton] = useState('organisation');

  const MySwal = withReactContent(Swal);

  const formRef = useRef(null);

  const handleButtonClick = (buttonType) => {
    setUserType(buttonType);
    setActiveButton(buttonType);
  

    // if (formRef.current) {
    //   formRef.current.scrollIntoView({ behavior: 'smooth' , block:"start"});
    //   window.scrollBy(0, 500); 
    // }

    const formPosition = formRef.current.getBoundingClientRect().top;

    
    window.scrollTo({
      top: window.pageYOffset + formPosition - 290,
      behavior: 'smooth',
    });
    
  };

  const buttonClasses = (buttonType) => {
    return `user-button ${buttonType === activeButton ? 'active' : ''}`;
  };
  // const [selectCity, setSelectCity] = useState("");
  // const [otherCity, setOtherCity] = useState("");

  // const handleCityChange = (e) => {
  //   const { value } = e.target;
  //   if (value === "other") {
  //     setOtherCity("");
  //   }
  //   setSelectCity(value);
  // };




  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      // Checks if input consists of 10 digits or less
      setNumber(inputValue);
      if (inputValue.length !== 10) {
        setError("Number must be exactly 10 digits long");
      } else {
        setError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    /*
    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "service_k6lmbm8";
    const templateId = "template_ypfremd";
    const publicKey = "kHo1cvgHPOMZrnrRB";

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_number: number,
      from_email: email,
      to_name: "",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
    .send(serviceId, templateId, templateParams, publicKey)
    .then((response) => {
      console.log("Email sent successfully!", response);
      setName("");
      setNumber("");
      setEmail("");
      setMessage("");
      setCityName("");
      // setSelectCity("");
      // setOtherCity("")

      setTimeout(() => {
        setIsSuccess(true);
      }, 10000);
    })

    .catch((error) => {
      console.error("Error sending email:", error);
    });
    */

    

    MySwal.showLoading();
    const response = await httpSendMail('sendcontactmail', {name, email, number, message, userType, cityName});
    setOther("");
    setName("");
    setNumber("");
    setEmail("");
    setMessage("");
    setCityName("");

    const success = response.ok;

    if(success){
      console.log('request sent');
      MySwal.fire({
        title: "Mail sent successfully!",
        icon: "success"
      });
    }
    else{
      console.log('request failed');
      MySwal.fire({
        title: "Failed to send mail!",
        icon: "error"
      });
    }
  };
  return (
    <>
      <section id="about-5">
        <header className="header-container-10">
          <img
            src="./img/header-img-section.jpg"
            className="header-image-10"
            alt="HeaderImage"
          />
          <div className="header-text-10">
            {/* <p className="para-header-6"> About Us</p> */}
            <h2 className="header-head-10">Contact Us</h2>
          </div>
        </header>
      </section>

      <section id="contact-us">
        <div class="container">
          <div class="row ">
            <div class="col-md-4 contact-26 ">
              <img
                className="contact-banner-resize"
                src="./img/contact-banner-3.png"
                alt="contact-banner"
              />
            </div>
            <div class="col-md-4 contact-27">
              <div className="title-container">
                <h2 className="get-in">Get in touch with us</h2>
              </div>

              <div className="title-paragraph">
                <p className="get-para">What describes you the best?</p>
              </div>

              <div class="button-container">
                <div class="row row-50">
                  <button
                    className={buttonClasses("organisation")}
                    onClick={() => handleButtonClick("organisation") }
                  
                  >
                    <i class="fa fa-building user-icon-30"></i>
                    <div class="user-text">
                      <h3 className="customer">Organisation</h3>
                      <p className="all-para">
                        Looking for canteen management system or caterer
                      </p>
                    </div>
                  </button>
                  <button
                    className={buttonClasses("caterer")}
                    onClick={() => handleButtonClick("caterer")}
                  >
                    <i class="fas fa-user-tie user-icon-30"></i>
                    <div class="user-text">
                      <h3 className="customer">Caterer</h3>
                      <p className="all-para">
                        Looking to partner with us to expand business
                      </p>
                    </div>
                  </button>
                </div>
              </div>

              <div class="button-container">
                <div class="row row-50">
                <button
                    className={buttonClasses("existing")}
                    onClick={() => handleButtonClick("existing")}

                  >
                    <i class="fas fa-user-tie user-icon-30"></i>

                    <div class="user-text">
                      <h3 className="customer">Existing Customer</h3>
                      <p className="all-para">
                        Looking to partner with us to expand business
                      </p>
                    </div>
                  </button>
                  <button
                    className={buttonClasses("other")}
                    onClick={() => handleButtonClick("other")}
                  >
                    <i class="fa fa-users user-icon-30"></i>
                    <div class="user-text">
                      <h3 className="customer">Other</h3>
                      <p className="all-para">For anything else</p>
                    </div>
                  </button>
                </div>
              </div>

              {/* <div class="button-container">
                <div class="row row-50">
                  <button
                    className="user-button"
                    onClick={() => setUserType("existing")}
                  >
                    <i class="fas fa-user-tie user-icon-22"></i>
                    Existing Customer
                  </button>
                  <button
                    className="user-button"
                    onClick={() => setUserType("other")}
                  >
                    <i class="fa fa-users user-icon-21"></i>
                    Other
                  </button>
                </div>
              </div> */}

              {userType === "organisation" && (
                <>
                  <h2 className="existing-21">Organisation</h2>
                  <form ref={formRef} onSubmit={handleSubmit} className="emailForm">
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="number"
                      placeholder="Number"
                      value={number}
                      required
                      onChange={handleChange}
                    />{" "}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <br />
                    <input
                      className="input-1"
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="text"
                      placeholder="City"
                      value={cityName}
                      required
                      onChange={(e) => setCityName(e.target.value)}
                    />{" "}
                    <br />
                    {/* <select
                      className="input-1"
                      value={selectCity}
                      required
                    
                      onChange={handleCityChange}

                    >
                      <option value="">Select City</option>
                      <option value="Agra">Agra</option>
                      <option value="Bangalore">Bengalore</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Jaipur">Jaipur</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                      <option value="Surat">Surat</option>

                      <option value="other">Other</option>
                    </select>
                    <br /> */}
                    {/* {selectCity === "other" && (
                      <input
                        className="input-1"
                        type="text"
                        placeholder="Other city name"
                        value={otherCity}
                        onChange={(e) => setOtherCity(e.target.value)}
                      />
                    )} */}
                    <textarea
                      className="textarea-1"
                      placeholder="Please share details of your query here"
                      cols="30"
                      rows="4"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>{" "}
                    <br />
                    <div className="main-check">
                      <div className="second-check">
                        <input type="checkbox" id="consentCheckbox" required />
                      </div>

                      <p className="third-check">
                        By completing this form, you acknowledge and consent to
                        being contacted by CampCard and/or its affiliated
                        entities through phone calls, email, or WhatsApp
                        messages.
                      </p>
                    </div>
                    <br />
                    <button type="submit" className="btn-email">
                      Submit
                    </button>
                    {isSuccess && (
                      <div className="success-message">
                        Email sent successfully!
                      </div>
                    )}
                  </form>
                </>
              )}

              {userType === "caterer" && (
                <>
                  <h2 className="existing-21">Caterer</h2>
                  <form ref={formRef} onSubmit={handleSubmit} className="emailForm">
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="number"
                      placeholder="Number"
                      value={number}
                      required
                      onChange={handleChange}
                    />{" "}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <br />
                    <input
                      className="input-1"
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="text"
                      placeholder="City"
                      value={cityName}
                      required
                      onChange={(e) => setCityName(e.target.value)}
                    />{" "}
                    <br />
                    {/* <select
                      className="input-1"
                      value={selectCity}
                      required
                    
                      onChange={handleCityChange}

                    >
                      <option value="">Select City</option>
                      <option value="Agra">Agra</option>
                      <option value="Bangalore">Bengalore</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Jaipur">Jaipur</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                      <option value="Surat">Surat</option>

                      <option value="other">Other</option>
                    </select>
                    <br /> */}
                    {/* {selectCity === "other" && (
                      <input
                        className="input-1"
                        type="text"
                        placeholder="Other city name"
                        value={otherCity}
                        onChange={(e) => setOtherCity(e.target.value)}
                      />
                    )} */}
                    <textarea
                      className="textarea-1"
                      placeholder="Please share details of your query here"
                      cols="30"
                      rows="4"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>{" "}
                    <br />
                    <div className="main-check">
                      <div className="second-check">
                        <input type="checkbox" id="consentCheckbox" required />
                      </div>

                      <p className="third-check">
                        By completing this form, you acknowledge and consent to
                        being contacted by CampCard and/or its affiliated
                        entities through phone calls, email, or WhatsApp
                        messages.
                      </p>
                    </div>
                    <br />
                    <button type="submit" className="btn-email">
                      Submit
                    </button>
                    {isSuccess && (
                      <div className="success-message">
                        Email sent successfully!
                      </div>
                    )}
                  </form>
                </>
              )}

              {userType === "existing" && (
                <>
                  <h2 className="existing-21">Existing Users</h2>
                  <form ref={formRef} onSubmit={handleSubmit} className="emailForm">
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="number"
                      placeholder="Number"
                      value={number}
                      required
                      onChange={handleChange}
                    />{" "}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <br />
                    <input
                      className="input-1"
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="text"
                      placeholder="City"
                      value={cityName}
                      required
                      onChange={(e) => setCityName(e.target.value)}
                    />{" "}
                    <br />
                    {/* <select
                      className="input-1"
                      value={selectCity}
                      required
                    
                      onChange={handleCityChange}

                    >
                      <option value="">Select City</option>
                      <option value="Agra">Agra</option>
                      <option value="Bangalore">Bengalore</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Jaipur">Jaipur</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                      <option value="Surat">Surat</option>

                      <option value="other">Other</option>
                    </select>
                    <br /> */}
                    {/* {selectCity === "other" && (
                      <input
                        className="input-1"
                        type="text"
                        placeholder="Other city name"
                        value={otherCity}
                        onChange={(e) => setOtherCity(e.target.value)}
                      />
                    )} */}
                    <textarea
                      className="textarea-1"
                      placeholder="Please share details of your query here"
                      cols="30"
                      rows="4"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>{" "}
                    <br />
                    <div className="main-check">
                      <div className="second-check">
                        <input type="checkbox" id="consentCheckbox" required />
                      </div>

                      <p className="third-check">
                        By completing this form, you acknowledge and consent to
                        being contacted by CampCard and/or its affiliated
                        entities through phone calls, email, or WhatsApp
                        messages.
                      </p>
                    </div>
                    <br />
                    <button type="submit" className="btn-email">
                      Submit
                    </button>
                    {isSuccess && (
                      <div className="success-message">
                        Email sent successfully!
                      </div>
                    )}
                  </form>
                </>
              )}

              {userType === "other" && (
                <>
                  <h2 className="existing-21">Other Users</h2>
                  <form ref={formRef} onSubmit={handleSubmit} className="emailForm">
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Please Specify"
                      value={other}
                      required
                      onChange={(e) => setOther(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="number"
                      placeholder="Number"
                      value={number}
                      required
                      onChange={handleChange}
                    />{" "}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <br />
                    <input
                      className="input-1"
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="text"
                      placeholder="City"
                      value={cityName}
                      required
                      onChange={(e) => setCityName(e.target.value)}
                    />{" "}
                    <br />
                    <textarea
                      className="textarea-1"
                      placeholder="Please share details of your query here"
                      cols="30"
                      rows="4"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>{" "}
                    <br />
                    <div className="main-check">
                      <div className="second-check">
                        <input type="checkbox" id="consentCheckbox" required />
                      </div>

                      <p className="third-check">
                        By completing this form, you acknowledge and consent to
                        being contacted by CampCard and/or its affiliated
                        entities through phone calls, email, or WhatsApp
                        messages.
                      </p>
                    </div>
                    <br />
                    <button type="submit" className="btn-email">
                      Submit
                    </button>
                    {isSuccess && (
                      <div className="success-message">
                        Email sent successfully!
                      </div>
                    )}
                  </form>
                </>
              )}
            </div>
            {/* <div class="col-md-4 contact-11">
              <div className="title-container">
                <h2 className="get-in">GET IN TOUCH WITH US</h2>
              
              </div>

              <div className="button-container">
                <button
                  className="user-button-3"
                  onClick={() => setUserType("existing")}
                >
                  <i class="fas fa-user-tie user-icon-20"></i>
                  Existing Users
                </button>
                <button
                  className="user-button-3"
                  onClick={() => setUserType("other")}
                >
                
                  <i class="fa fa-users user-icon-20"></i>
                  Other Users
                </button>
              </div>

              {userType === "existing" && (
                <>
                  <h2 className="existing-21">Existing Users</h2>
                  <form onSubmit={handleSubmit} className="emailForm">
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Your Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="number"
                      placeholder="Your Number"
                      value={number}
                      required
                      onChange={handleChange}
                    />{" "}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <br />
                    <input
                      className="input-1"
                      type="email"
                      placeholder="Your Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <br />
                    <select
                      className="input-1"
                      value={selectCity}
                      required
                      onChange={(e) => setSelectCity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Bangalore">Bangalore</option>

                      <option value="other">Other</option>
                    </select>
                    <br />
                    <textarea
                      className="textarea-1"
                      placeholder="Message"
                      cols="30"
                      rows="10"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>{" "}
                    <br />
                    <button type="submit" className="btn-email">
                      Send Email
                    </button>
                    {isSuccess && (
                      <div className="success-message">
                        Email sent successfully!
                      </div>
                    )}
                  </form>
                </>
              )}

              {userType === "other" && (
                <>
                  <h2 className="existing-21">Other Users</h2>
                  <form onSubmit={handleSubmit} className="emailForm">
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Please Specify"
                      value={other}
                      required
                      onChange={(e) => setOther(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="text"
                      placeholder="Your Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                    <br />
                    <input
                      className="input-1"
                      type="number"
                      placeholder="Your Number"
                      value={number}
                      required
                      onChange={handleChange}
                    />{" "}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <br />
                    <input
                      className="input-1"
                      type="email"
                      placeholder="Your Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <br />
                    <select
                      className="input-1"
                      value={selectCity}
                      required
                      onChange={(e) => setSelectCity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Bangalore">Bangalore</option>

                      <option value="other">Other</option>
                    </select>
                    <br />
                    <textarea
                      className="textarea-1"
                      placeholder="Message"
                      cols="30"
                      rows="10"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>{" "}
                    <br />
                    <button type="submit" className="btn-email">
                      Send Email
                    </button>
                    {isSuccess && (
                      <div className="success-message">
                        Email sent successfully!
                      </div>
                    )}
                  </form>
                </>
              )}
            </div> */}
            {/* <div class="col-md-4 contact-12">
              <h2 className="heading-15">Contact Us</h2>

              <div className="first-icon">
                <div className="section-icon">
                  <i class="fa fa-phone phone-icon"></i>
                </div>

                <div className="section-para">
                  <p className="para-100"> 022 6673 7300</p>
                </div>
              </div>

              <div className="second-icon">
                <div className="section-icon-1">
                  <i class="fa fa-map-marker location-icon-1"></i>
                </div>

                <div className="section-para-1">
                  <p className="para-101">
                    1215B, Lodha Supremus, Kolshet Rd, Thane, Maharashtra 400607
                  </p>
                </div>
              </div>

              <div className="third-icon">
                <div className="section-icon-2">
                  <i class="fa fa-envelope email-icon"></i>
                </div>

                <div className="section-para-2">
                  <p className="para-102">info.12@gmail.com</p>
                  
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
