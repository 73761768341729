import React from "react";
//import emailjs from "@emailjs/browser";
import { useState } from "react";
import { httpSendMailwithFile } from '../hooks/requests';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Career = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  //const [isSuccess, setIsSuccess] = useState(false);
  const [fileChoose, setFileChoose] = useState(null);
  // const [userType, setUserType] = useState("existing");
  // const [other, setOther] = useState("");

  const [error, setError] = useState("");
  const [roleName, setRoleName] = useState("");
  document.title = "Career";

  const MySwal = withReactContent(Swal);

  const handleEmailClick = () => {
    window.location.href = "https://www.google.com/gmail";
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      // Checks if input consists of 10 digits or less
      setNumber(inputValue);
      if (inputValue.length !== 10) {
        setError("Number must be exactly 10 digits long");
      } else {
        setError("");
      }
    }
  };

  const handleFileChange = (e) => {
    console.log(e.target.files);
    setFileChoose(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    /* EmailJS not used
    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "service_k6lmbm8";
    const templateId = "template_ypfremd";
    const publicKey = "kHo1cvgHPOMZrnrRB";

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_number: number,
      from_email: email,
      to_name: "",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setName("");
        setNumber("");
        setEmail("");
        setMessage("");
        setRoleName("");
        setFileChoose("");

        // setSelectCity("");
        // setOtherCity("")

        setTimeout(() => {
          setIsSuccess(true);
        }, 10000);
      })

      .catch((error) => {
        console.error("Error sending email:", error);
      });
    */

    const formData = new FormData();
    console.log(fileChoose);
    formData.append('file', fileChoose);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('number', number);
    formData.append('message', message);
    formData.append('rolename', roleName);

    MySwal.showLoading();
    const response = await httpSendMailwithFile('sendcareermail', formData);

    setName("");
    setNumber("");
    setEmail("");
    setMessage("");
    setRoleName("");
    setFileChoose(null);

    const success = response.ok;

    if(success){
      console.log('request sent');
      MySwal.fire({
        title: "Mail sent successfully!",
        icon: "success"
      });
    }
    else{
      console.log('request failed');
      MySwal.fire({
        title: "Failed to send mail!",
        icon: "error"
      });
    }
  };

  return (
    <>
      <section id="about-5">
        <header className="header-container-10">
          <img
            src="./img/header-img-section.jpg"
            className="header-image-10"
            alt="HeaderImage"
          />
          <div className="header-text-10">
            {/* <p className="para-header-6"> About Us</p> */}
            <h2 className="header-head-10">Career</h2>
          </div>
        </header>
      </section>

      <section id="career">
        <div class="container ">
          <div class="row">
            <div class="col-md-6">
              <img
                className="career-banner"
                src="./img/career-banner.png"
                alt="carer"
              />
            </div>
            <div class="col-md-6">
              <h2 className="career-head">Join Us</h2>

              <form onSubmit={handleSubmit} className="emailForm">
                <input
                  className="input-1"
                  type="text"
                  placeholder="Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />{" "}
                <br />
                <input
                  className="input-1"
                  type="number"
                  placeholder="Number"
                  value={number}
                  required
                  onChange={handleChange}
                />{" "}
                {error && <p style={{ color: "red" }}>{error}</p>}
                <br />
                <input
                  className="input-1"
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />{" "}
                <br />
                <input
                  className="input-1"
                  type="text"
                  placeholder="Role Interested"
                  value={roleName}
                  required
                  onChange={(e) => setRoleName(e.target.value)}
                />{" "}
                <br />
                <input
                  className="file-choose"
                  type="file"
                  required
                  onChange={handleFileChange}
                />
                <br />
                <textarea
                  className="textarea-1"
                  placeholder="Please share details of your query here"
                  cols="30"
                  rows="4"
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>{" "}
                <br />
                <button type="submit" className="btn-email">
                  Submit
                </button>
                <p className="career-para">
                  For more information, write to us at &nbsp;
                  <a
                    href="mailto:hr@campcardsolutions.com"
                    onClick={handleEmailClick}
                  >
                    hr@campcardsolutions.com
                  </a>

                  
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
